import { PopupButton } from 'react-calendly';

const Navbar = () => {
  return (
    <nav className="flex justify-between items-center p-4 bg-white sticky top-0 z-10">
      {/* Left Logo */}
      <div className="flex items-center w-48">
        <img
          src="/logo.svg"
          alt="Increasify Logo"
          className="w-8 h-8"
        />
        <span className="ml-2 font-bold text-xl">INCREASIFY</span>
      </div>

      {/* Right Button */}
      <PopupButton
        url="https://calendly.com/increasify-io/30min"
        text="Book a call"
        rootElement={document.getElementById("root")!}
        className="bg-purple-600 text-white py-2 px-4 w-48 rounded-full text-md font-bold hover:bg-purple-700 transition duration-300"
      />
    </nav>
  );
};

export default Navbar;
