// src/components/LeadsMessage.tsx

import React from 'react';

const LeadsMessage: React.FC = () => {
    return (
        <div className="bg-white flex justify-center items-center my-12 mx-2">
            <div className="text-center">
                {/* Heading */}
                <h1 className="text-3xl font-semibold mb-10">Our Proven MVP Process</h1>

                <div className="flex flex-row justify-evenly lg:space-x-24 items-center">

                    <img
                        src="/process.svg"
                        className="hidden lg:block object-scale-down w-[35vw]"
                        alt="Person using a Computer"
                    />

                    <div className="text-start space-y-6">
                        <div className="flex items-center">
                            <div>
                                <img
                                    src="/search-icon.svg"
                                    className="w-10 h-10 mx-6"
                                    alt="Search"
                                />
                            </div>
                            <div>
                                <h2 className="text-xl font-semibold">1. Discovery</h2>
                                <p>We dig deep to understand your vision and market.</p>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <div>
                                <img
                                    src="/design-icon.svg"
                                    className="w-10 h-10 mx-6"
                                    alt="Design"
                                />
                            </div>
                            <div>
                                <h2 className="text-xl font-semibold">2. Design</h2>
                                <p>We craft intuitive UI/UX that users will love.</p>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <div>
                                <img
                                    src="/dev-icon.svg"
                                    className="w-10 h-10 mx-6"
                                    alt="Development"
                                />
                            </div>
                            <div>
                                <h2 className="text-xl font-semibold">3. Development</h2>
                                <p>We build your MVP with scalable, clean code.</p>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <div>
                                <img
                                    src="/deploy-icon.svg"
                                    className="w-10 h-10 mx-6"
                                    alt="Deploy"
                                />
                            </div>
                            <div>
                                <h2 className="text-xl font-semibold">4. Delivery</h2>
                                <p>We launch your MVP and provide ongoing support.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeadsMessage;
