// src/components/TrafficMessage.tsx

import React from 'react';

const TrafficMessage: React.FC = () => {
    return (
        <div className="bg-purple-600 flex justify-center items-center xl:py-6">
            <div className="bg-purple-600 text-white text-center p-10 rounded-lg">
                <h1 className="text-3xl xl:text-4xl font-bold mb-8">Why Choose Us?</h1>
                <div className="flex flex-col space-y-8 md:flex-row md:space-y-0 lg:space-x-8 items-start justify-center text-center">
                    {/* First Block */}
                    <div className="flex flex-col items-center">
                        <span className="text-4xl xl:text-5xl mb-2">⚡️</span>
                        <p className="px-6 xl:text-xl font-semibold">Lightning-Fast Delivery</p>
                        <p className="px-6 xl:text-xl">Go from concept to working MVP in as little as 2-4 weeks.</p>
                    </div>

                    {/* Second Block */}
                    <div className="flex flex-col items-center">
                        <span className="text-4xl xl:text-5xl mb-2">🤩</span>
                        <p className="px-6 xl:text-xl font-semibold">Uncompromising Quality</p>
                        <p className="px-6 xl:text-xl">Clean code, scalable architecture, and user-centric design.</p>
                    </div>

                    {/* Third Block */}
                    <div className="flex flex-col items-center">
                        <span className="text-4xl xl:text-5xl mb-2">📦</span>
                        <p className="px-6 xl:text-xl font-semibold">Market-Ready Package</p>
                        <p className="px-6 xl:text-xl">Web application and landing page delivered to test your concept</p>
                    </div>

                    {/* Fourth Block */}
                    <div className="flex flex-col items-center">
                        <span className="text-4xl xl:text-5xl mb-2">💰</span>
                        <p className="px-6 xl:text-xl font-semibold">Maximize ROI</p>
                        <p className="px-6 xl:text-xl">Fast-track your launch while cutting costs.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrafficMessage;
