import React from 'react';

const MainContent = () => {
  return (
    <section className="text-center bg-white">
      <div className="flex flex-row justify-around m-6 mx-8 md:mx-8 lg:mx-20 xl:py-10 lg:py-19">
        <div className="flex flex-col lg:items-start mb-16">
          {/* Heading */}
          <h1 className="text-5xl font-bold text-center lg:text-left my-4 xl:mt-16 lg:hidden">
            We build your MVP&nbsp;
            <span className="bg-yellow-200">in weeks</span>, not months
          </h1>

          <h1 className="text-5xl md:text-4xl lg:text-5xl xl:text-7xl font-bold text-center lg:text-left mt-10 xl:mt-16 hidden lg:block">
            We build your MVP<br />
            <span className="bg-yellow-200">in weeks</span>, not months
          </h1>

          {/* Benefits */}
          <div className="my-6 lg:my-12">
            <ul className="text-left text-black text-md lg:text-lg font-medium list-disc space-y-2">
              <li>
                Functional MVPs delivered in 2-4 weeks for fast testing and feedback
              </li>
              <li>
                Includes web application and landing page, ready to launch and validate your idea
              </li>
              <li>
                Built with the latest tech for stability, speed, and scalability
              </li>
              <li>
                Years of startup success and SEO-optimized solutions to boost your product's visibility
              </li>
            </ul>
          </div>

          {/* Call to Action */}
          <div>
            <a
              href='https://forms.gle/xvwKNGMSFsdHh2hH6'
              target="_blank"
              rel="noreferrer"
              className="bg-purple-600 text-white py-4 lg:py-6 px-8 lg:px-12 rounded-full text-lg lg:text-2xl font-bold hover:bg-purple-700 transition duration-300"
            >
              Get Started
            </a>
          </div>
        </div>

        <img
          src="/computer.svg"
          className="hidden lg:block object-scale-down w-[35vw] mt-10 lg:mt-0"
          alt="Person using a Computer"
        />
      </div>
    </section>
  );
};

export default MainContent;
