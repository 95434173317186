import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import MainContent from './components/MainContent';
import TrafficMessage from './components/TrafficMessage';
import LeadsMessage from './components/LeadsMessage';
import Footer from './components/Footer';
import mixpanel from "mixpanel-browser";
import CaseStudies from './components/CaseStudies';
import Pricing from './components/Pricing';

const App: React.FC = () => {
  // Near entry of your product, init Mixpanel
  mixpanel.init("4ee95e1d4655a3b8cafa1a8e74a462df", {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
  });
  return (
    <div className="App">
      <Navbar />
      <MainContent />
      <TrafficMessage />
      <CaseStudies />
      <LeadsMessage />
      <Pricing />
      <Footer />
    </div>
  );
}

export default App;
