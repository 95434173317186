import React from "react";

const CaseStudies = () => {
    return (
        <section className="mt-16">
            <h2 className="text-3xl font-bold text-center mb-12">
                Case Studies
            </h2>
            <div className="bg-slate-50 rounded-lg mx-6">
                <div className="flex flex-col sm:flex-row items-center">
                    <div className="w-1/3 lg:w-1/5 pl-4 pt-8">
                        <img
                            src="./noteit-icon.png"
                            alt="noteit widget"
                            className="rounded-lg w-200 h-200"
                        />
                    </div>
                    <div className="p-8">
                        <h3 className="text-2xl font-semibold mb-4">noteit widget</h3>
                        <p className="mb-4">
                            The "noteit widget" app rapidly reached #1 on the App Store charts, a
                            llowing users to send notes directly to their loved ones' home screens.
                            With speed to market being essential, the MVP was built with real-time updates,
                            an intuitive user interface, and seamless functionality.
                        </p>
                        <a
                            href="https://apps.apple.com/us/app/noteit-bff-widget/id1570369625"
                            className="text-purple-600 hover:underline font-semibold"
                        >
                            App Store
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CaseStudies;
