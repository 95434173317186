// src/components/Footer.tsx

import React from 'react';

const Footer: React.FC = () => {
    return (
        <footer className="bg-purple-600 text-white py-5 px-12">
            <div className="max-w-7xl mx-auto flex flex-col-reverse md:flex-row justify-between items-start">
                {/* Company Info Section */}
                <div>
                    <div className="flex items-center mb-2 mt-4 md:mt-0">
                        <img src="/white-logo.svg" alt="Increasify Logo" className="w-6 h-6" />
                        <h1 className="text-2xl font-bold mb-1">INCREASIFY</h1>
                    </div>
                    <p className="mb-1 max-w-80">
                        From idea to product, we help startups build MVPs that attract users and investors in just weeks.
                    </p>
                    <p className="text-sm">Copyright © 2024 - All rights reserved</p>
                </div>

                {/* Links Section */}
                <div className="flex items-start pt-2">
                    {/* <div className="mr-12">
                        <h2 className="font-semibold mb-1">LINKS</h2>
                        <ul>
                            <li>Support</li>
                            <li>Pricing</li>
                        </ul>
                    </div>

                    <div className="mr-12">
                        <h2 className="font-semibold mb-1">LEGAL</h2>
                        <ul>
                            <li>Terms of services</li>
                            <li>Privacy policy</li>
                        </ul>
                    </div> */}

                    <div>
                        <h2 className="font-semibold mb-1">CONTACT</h2>
                        <ul>
                            <li><a href="mailto:august@increasify.us">august@increasify.us</a></li>
                            <li><a href="tel:+12137033216">+1 (213) 703-3216</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
