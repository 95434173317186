import React from "react";

const Pricing = () => {
    return (
        <section className="bg-white my-16">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-12">
                    Simple, Transparent Pricing
                </h2>
                <div className="flex flex-col sm:flex-row justify-center items-stretch gap-8">
                    {/* Basic Plan */}
                    <div className="w-full sm:w-1/3 bg-gray-100 rounded-lg shadow-md p-8 text-center flex flex-col">
                        <h3 className="text-2xl font-semibold mb-4">Landing Page</h3>
                        <p className="text-gray-600 mb-2">Launch your online presence with confidence.</p>
                        <div className="text-2xl font-bold line-through text-gray-400">$1,299</div>
                        <div className="text-4xl font-bold mb-4 text-black">$899</div>
                        <p className="text-sm text-[#16A34A] mb-6 flex">
                            <img src="./gift-icon.svg" alt="Gift Icon" className="w-4 h-4" />
                            Early bird discount –&nbsp;<span className="font-bold">5 spots left!</span>
                        </p>
                        <ul className="text-gray-600 mb-8 space-y-2 flex-grow text-left">
                            <li className="flex items-center">
                                <span className="text-[#16A34A] mr-2">✓</span> High-converting copywriting tailored to your audience.
                            </li>
                            <li className="flex items-center">
                                <span className="text-[#16A34A] mr-2">✓</span> Custom, mobile-responsive design.
                            </li>
                            <li className="flex items-center">
                                <span className="text-[#16A34A] mr-2">✓</span> Marketing tool integration for email capture and sharing.
                            </li>
                            <li className="flex items-center">
                                <span className="text-[#16A34A] mr-2">✓</span> Hassle-free setup and deployment.
                            </li>
                        </ul>
                        <a
                            href='https://forms.gle/xvwKNGMSFsdHh2hH6'
                            target="_blank"
                            rel="noreferrer"
                            className="bg-purple-600 text-white py-3 px-6 rounded-lg hover:bg-purple-700 mt-auto"
                        >
                            Claim Spot
                        </a>
                    </div>
                    {/* Pro Plan */}
                    <div className="w-full sm:w-1/3 bg-white border-2 border-purple-600 rounded-lg shadow-md p-8 text-center flex flex-col">
                        <h3 className="text-2xl font-semibold mb-4">MVP</h3>
                        <p className="text-gray-600 mb-2">Transform ideas into functional applications.</p>
                        <div className="text-2xl font-bold line-through text-gray-400">$4,999</div>
                        <div className="text-4xl font-bold mb-4 text-black">$3,999</div>
                        <p className="text-sm text-[#16A34A] mb-6 flex">
                            <img src="./gift-icon.svg" alt="Gift Icon" className="w-4 h-4" />
                            Early bird discount –&nbsp;<span className="font-bold">3 spots left!</span>
                        </p>
                        <ul className="text-gray-600 mb-8 space-y-2 flex-grow text-left">
                            <li className="flex items-center">
                                * Everything from Landing Page plus:
                            </li>
                            <li className="flex items-center">
                                <span className="text-[#16A34A] mr-2">✓</span> Web/Mobile app
                            </li>
                            <li className="flex items-center">
                                <span className="text-[#16A34A] mr-2">✓</span> Login and registration.
                            </li>
                            <li className="flex items-center">
                                <span className="text-[#16A34A] mr-2">✓</span> Payment processing integration.
                            </li>
                            <li className="flex items-center">
                                <span className="text-[#16A34A] mr-2">✓</span> Database integration.
                            </li>
                            <li className="flex items-center">
                                <span className="text-[#16A34A] mr-2">✓</span> A/B testing options to optimize performance and increase engagement.
                            </li>
                        </ul>
                        <a
                            href='https://forms.gle/xvwKNGMSFsdHh2hH6'
                            target="_blank"
                            rel="noreferrer"
                            className="bg-purple-600 text-white py-3 px-6 rounded-lg hover:bg-purple-700 mt-auto"
                        >
                            Claim Spot
                        </a>
                    </div>
                    {/* Enterprise Plan */}
                    <div className="w-full sm:w-1/3 bg-gray-100 rounded-lg shadow-md p-8 text-center flex flex-col">
                        <h3 className="text-2xl font-semibold mb-4">Consulting</h3>
                        <p className="text-gray-600 mb-3">Tailored solutions for your unique needs.</p>
                        <div className="text-gray-400">Starting at:</div>
                        <div className="text-4xl font-bold mb-4 text-black">$499</div>
                        <p className="text-gray-500 mb-4 text-left">Data-Driven strategies to maxiamize app sign-ups, retention and sales.</p>
                        <ul className="text-gray-600 mb-8 space-y-2 flex-grow">
                            <li className="flex items-center">
                                <span className="text-[#16A34A] mr-2">✓</span> Data driven
                            </li>
                            <li className="flex items-center">
                                <span className="text-[#16A34A] mr-2">✓</span> Custom feature development
                            </li>
                            <li className="flex items-center">
                                <span className="text-[#16A34A] mr-2">✓</span> Proactive monitoring
                            </li>
                            <li className="flex items-center">
                                <span className="text-[#16A34A] mr-2">✓</span> Regular security updates
                            </li>
                            <li className="flex items-center">
                                <span className="text-[#16A34A] mr-2">✓</span> Advanced integrations
                            </li>
                            <li className="flex items-center">
                                <span className="text-[#16A34A] mr-2">✓</span> 24/7 Support
                            </li>
                        </ul>
                        <a
                            href='https://forms.gle/xvwKNGMSFsdHh2hH6'
                            target="_blank"
                            rel="noreferrer"
                            className="bg-purple-600 text-white py-3 px-6 rounded-lg hover:bg-purple-700 mt-auto"
                        >
                            Contact Us
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Pricing;
